import { MeilisearchIndexQuery } from "../../plugins/gatsby-plugin-meilisearch"
import { translateCountryReducer, uniqueByValueReducer } from "./commonUtils"

const model = require("./model")
const orgQuery = `{
    categories: allAirtable(filter: {table: {eq: "Category"}, data: {Name: {ne: null}}}) {
        nodes {
            id
            data {
                Order
                Name
                Description {
                    childMarkdownRemark {
                      html
                    }
                }
                ContributionType
                NewOrgCount
            }
        }
    }
    organizations: allAirtable(filter: { table: { eq: "Organization" }, data: {Status: {eq: "Validé"}}} ) {
        nodes {
            id
            data {
                Name
                ActivityDescription {
                    childMarkdownRemark {
                      html
                    }
                }
                Categories {
                    id
                    data {
                        Name
                        Order
                    }
                }
                StructureType
                CreationYear
                Workforce
                Headquarter {
                    id
                    data {
                        CityName
                        GPSCoordinates
                        DepartmentName
                        RegionName
                        Country
                        Dpt2
                    }
                }
                City {
                    id
                    data {
                        CityName
                        GPSCoordinates
                        DepartmentName
                        RegionName
                        Dpt2
                    }
                }
                JobBoardOrgID
                Linkedin
                Website
                Tags {
                    id
                    data {
                        Tag
                    }
                }
                Lever {
                    id
                    data {
                        Name
                    }
                }
                Trainings {
                    id
                    data {
                        Training
                    }
                }
                Professions {
                    id
                    data {
                        Profession
                    }
                }
            }
        }
    }
  }`

function parseCoordinate(coordinate: string) {
  const [latitude, longitude] = coordinate.split(",")
  return {
    latitude: parseFloat(latitude),
    longitude: parseFloat(longitude),
  }
}

function getLocationName(cityName, cityDep) {
  return `${cityName.toUpperCase()} (${cityDep})`
}

function setJobBoard(rawOrganization) {
  if (rawOrganization.data.JobBoardOrgID !== null) {
    rawOrganization.data.jobBoard = ["Jobs that makesense"]
  }
  return rawOrganization
}

function getDepartmentName(
  departmentName: string | undefined,
  departmentCode: string
): string {
  if (!departmentName) {
    return departmentCode
  }
  return `${departmentCode} - ${departmentName}`
}

const filterTruthy = v => Boolean(v)

export function getOrganizationFields(
  n: any,
  extraFields: Record<string, any>
) {
  const cities = n.data.City ?? []
  const headquarter = n.data.Headquarter[0].data
  const headquarterWithDep = getLocationName(
    headquarter.CityName,
    headquarter.Dpt2
  )
  const officeCitiesWithDep = cities.map(({ data: { CityName, Dpt2 } }) =>
    getLocationName(CityName, Dpt2)
  )
  const citiesDepartments = cities.map(({ data: { Dpt2, DepartmentName } }) =>
    getDepartmentName(DepartmentName, Dpt2)
  )
  const departments = [
    ...citiesDepartments,
    getDepartmentName(headquarter.DepartmentName, headquarter.Dpt2),
  ].reduce(uniqueByValueReducer, [])
  const regions = [
    ...cities.map(({ data: { RegionName } }) => RegionName?.[0]),
    headquarter.RegionName?.[0],
  ]
    .filter(filterTruthy)
    .reduce(uniqueByValueReducer, [])
  const countries = [...(cities
    .map(({ data: { Country } }) => Country?.[0])), headquarter.Country?.[0]
  ]
    .filter(filterTruthy)
    .reduce(uniqueByValueReducer, [])
    .reduce(translateCountryReducer, [])
  const officeCoordinates = cities
    .map(({ data: { GPSCoordinates } }) =>
      GPSCoordinates ? parseCoordinate(GPSCoordinates) : null
    )
    .filter(filterTruthy)
  const headquarterCoordinates = headquarter.GPSCoordinates
    ? parseCoordinate(headquarter.GPSCoordinates)
    : null
  const fullCoordinates = [headquarterCoordinates, ...officeCoordinates].filter(
    filterTruthy
  )
  return {
    id: n.id,
    fake: "*",
    name: n.data.Name,
    idMakeSense: n.data.JobBoardOrgID,
    desc: n.data.ActivityDescription?.childMarkdownRemark.html,
    tags: n.data.Tags?.map(t => t.data.Tag),
    trainings: n.data.Trainings?.map(t => t.data.Training),
    professions: n.data.Professions?.map(p => p.data.Profession),
    lever: n.data.Lever?.map(l => l.data.Name),
    workforce: n.data.Workforce,
    type: n.data.StructureType,
    creationYear: n.data.CreationYear,
    officeCities: officeCitiesWithDep,
    officeCoordinates,
    headquarter: headquarterWithDep,
    headquarterCoordinates,
    fullLocations: [headquarterWithDep, ...officeCitiesWithDep],
    fullCoordinates: fullCoordinates,
    departments,
    regions,
    countries,
    jobBoard: n.data.jobBoard,
    ...extraFields,
  }
}

const queries: MeilisearchIndexQuery[] = [
  {
    query: orgQuery,
    transformer: ({ data }) => {
      let categories = new model.Tree("root", data.categories.nodes)
      return data.organizations.nodes.map(organizationNode => {
        // todo: add logo
        let cats = {}
        let categoriesData = organizationNode.data.Categories
        if (categoriesData) {
          categoriesData.map(cat => {
            let current = categories.find(cat.data.Order)

            while (current.level > 0) {
              let k = model.levelMap[current.level]
              if (!cats.hasOwnProperty(k)) {
                cats[k] = []
              }
              cats[k].push(current.fullname)
              current = current.parent
            }
          })
        }
        organizationNode = setJobBoard(organizationNode)
        return getOrganizationFields(organizationNode, cats)
      })
    },
    indexName: process.env.GATSBY_MEILISEARCH_ORG_IDX_NAME,
    settings: {
      attributesForFaceting: [
        "lever",
        "workforce",
        "type",
        "secteur",
        "sous-secteur",
        "famille",
        "sous-famille",
        "fullLocations",
        "headquarter",
        "departments",
        "regions",
        "countries",
        "jobBoard",
      ],
      searchableAttributes: [
        "name",
        "tags",
        "trainings",
        "professions",
        "desc",
        "fake",
      ],
      displayedAttributes: [
        "id",
        "creationYear",
        "name",
        "desc",
        "tags",
        "lever",
        "workforce",
        "type",
        "secteur",
        "sous-secteur",
        "famille",
        "sous-famille",
        "headquarter",
        "headquarterCoordinates",
        "officeCities",
        "officeCoordinates",
        "fullLocations",
        "fullCoordinates",
        "departments",
        "regions",
        "countries",
        "idMakeSense",
      ],
    },
    sort: { order: "asc", key: "name" },
    options: {
      primaryKey: "id",
    },
  },
]

export default queries
